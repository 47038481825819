<template>
  <main id="content" role="main">
    <div class="container py-3">
      <div class="d-flex flex-wrap justify-content-between align-items-center">
        <div class="mb-3 mb-lg-0">
          <h1 class="h4 mb-0">
            {{ erc == 2 ? "RCH-20" : erc == 721 ? "RCH-721" : "Token" }} List
          </h1>
          <div class="mt-1"></div>
        </div>
        <div class="d-flex flex-wrap"></div>
      </div>
    </div>
    <div id="overlayMain"></div>
    <div class="container"></div>
    <div id="ContentPlaceHolder1_divSummary" class="container space-bottom-2">
      <div class="card">
        <!-- <div
          class="
            card-header
            d-flex
            justify-content-between
            align-items-center
            p-0
          "
        >
          <ul
            class="nav nav-custom nav-borderless nav_tabs1"
            id="nav_tabs"
            role="tablist"
          >
            <li id="ContentPlaceHolder1_li_transactions" class="nav-item">
              <a
                class="nav-link active"
                href="#transactions"
                data-title="Primary Transactions"
                data-toggle="tab"
                onclick="javascript:updatehash('');"
                >{{ getName("Token", "Token List") }}</a
              >
            </li>
          </ul>
        </div> -->

        <div class="card-body">
          <div class="tab-content">
            <div
              class="tab-pane fade show active"
              id="transactions"
              role="tabpanel"
              aria-labelledby="transactions-tab"
            >
              <div class="table-responsive mb-2 mb-md-0" v-loading="loading">
                <table class="table table-hover">
                  <thead class="thead-light">
                    <tr>
                      <!-- <th scope="col" width="20"></th> -->
                      <th scope="col" width="1">Logo</th>
                      <th scope="col" width="1">Memo</th>
                      <th scope="col" width="1">
                        {{ getName("Token", "Contract") }}
                      </th>

                      <th scope="col" width="1">
                        {{ getName("Token", "Symbol") }}
                      </th>

                      <th scope="col" width="1">
                        {{ getName("Token", "totalSupply") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in tokenList" :key="item._id">
                      <td>
                        <img
                          width="25"
                          v-if="item.logo && item.logo.length > 32"
                          :src="item.logo"
                          :alt="getName('Common', 'Thumbnail')"
                        />
                        <img
                          v-else
                          width="25"
                          src="../assets/images/empty_token.png"
                          :alt="getName('Common', 'Thumbnail')"
                        />
                      </td>
                      <td>
                        <a
                          class="hash-tag text-truncate myFnExpandBox_searchVal"
                        >
                          {{ item.memo }}
                        </a>
                      </td>
                      <td>
                        <a
                          class="text-truncate myFnExpandBox_searchVal"
                          href="#"
                          @click.prevent="showToken(item.address)"
                          >{{ item.address }}</a
                        >
                      </td>

                      <td>
                        <a
                          class="hash-tag text-truncate myFnExpandBox_searchVal"
                          href="#"
                          @click.prevent="showToken(item.address)"
                          >{{ item.symbol }}</a
                        >
                      </td>
                      <td>
                        <a
                          class="hash-tag text-truncate myFnExpandBox_searchVal"
                          href="#"
                          @click.prevent="showToken(item.address)"
                          >{{ item.totalSupply }}</a
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                id="ContentPlaceHolder1_topPageDiv"
                class="d-md-flex justify-content-between mb-4"
              >
                <p class="mb-2 mb-md-0"></p>

                <nav aria-label="page navigation">
                  <ul class="pagination pagination-sm mb-0">
                    <li class="page-item" data-toggle="tooltip" title="">
                      <a
                        class="page-link"
                        href="javascript:void(0)"
                        v-on:click="execPage(-1)"
                        aria-label="Previous"
                        ><span aria-hidden="True"
                          ><i class="fa fa-chevron-left small"></i
                        ></span>
                        <span class="sr-only">Previous</span></a
                      >
                    </li>

                    <li Class="page-item disabled">
                      <span Class="page-link text-nowrap"
                        >Page
                        <strong class="font-weight-medium">{{
                          currentPage
                        }}</strong>
                        of
                        <strong class="font-weight-medium">{{
                          parseInt(total / pageNum) + 1
                        }}</strong></span
                      >
                    </li>
                    <li class="page-item" data-toggle="tooltip" title="">
                      <a
                        class="page-link"
                        href="javascript:void(0)"
                        v-on:click="execPage(1)"
                        aria-label="Next"
                        ><span aria-hidden="True"
                          ><i class="fa fa-chevron-right small"></i
                        ></span>
                        <span class="sr-only">Next</span></a
                      >
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <div id="push"></div>
</template>
<script>
import { getName } from "../../public/assets/js/lang.js";
import { formatNumber } from "../../public/assets/js/utils.js";
import { PostNet, PostNetUrl } from "../../public/assets/js/myRequest.js";
import route from "../router/index";
import { getUrlKey } from "../assets/js/utils.js";

export default {
  name: "Tokens",
  data() {
    return {
      currentPage: 1,
      pageNum: 20,
      total: 0,
      tokenList: {},
      loading: false,
      erc: 1,
    };
  },
  mounted() {
    this.erc = parseInt(getUrlKey("num"));
    this.loading = true;
    PostNet.PostWith(
      PostNetUrl.tokenList,
      (info) => {
        this.loading = false;
        this.tokenList = info.data.data;
        this.total = info.data.count;
      },
      (err) => {
        this.loading = false;
      },
      { page: this.currentPage, pageNum: this.pageNum, erc: this.erc }
    );
  },
  methods: {
    getName(str1, str2) {
      return getName(str1, str2);
    },
    formatDate(time, fmt) {
      time = time * 1000;
      let date = new Date(time);
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      let o = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
      };
      for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          let str = o[k] + "";
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? str : this.padLeftZero(str)
          );
        }
      }
      return fmt;
    },
    padLeftZero(str) {
      return ("00" + str).substr(str.length);
    },
    formatNumber(number, decimail) {
      return formatNumber(number, decimail);
    },

    // 页面的方法
    showToken: function (address) {
      // location.href = "/token/index.html?c=" + address;
      route.push({
        path: "/tokenDetail",
        query: {
          c: address,
        },
      });
    },
    execPage: function (page) {
      this.currentPage = parseInt(this.currentPage) + parseInt(page);
      if (isNaN(this.currentPage)) {
        this.currentPage = 1;
      }
      if (this.currentPage <= 0) {
        this.currentPage = 1;
        return;
      }
      let totalPage = parseInt(this.total / this.pageNum) + 1;
      if (this.currentPage > totalPage) {
        this.currentPage = totalPage;
        return;
      }
      this.loading = true;
      PostNet.PostWith(
        PostNetUrl.tokenList,
        (info) => {
          // if (info.data.length > 0) {
          this.loading = false;
          this.tokenList = info.data.data;
          this.total = info.data.count;
          // }
        },
        (err) => {
          this.loading = false;
        },
        { page: this.currentPage, pageNum: this.pageNum }
      );
    },
  },
};
</script>
